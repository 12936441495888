import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GameCategoryWrapper from "../components/gameCategoryWrapper"
function AllCategories() {
  const { allGamesDataJson } = useStaticQuery(
    graphql`
      query allCategoryDataQuery {
        allGamesDataJson {
          group(field: category) {
            edges {
              node {
                category
                name
                id
                image
                link
                fullWidth
                externalLink
              }
            }
          }
        }
      }
    `
  )
  return (
    <div>
      {console.log(allGamesDataJson)}
      {allGamesDataJson.group.map((n, i) => {
        const category = n.edges[0].node.category
        const categoryName = category
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "-")
        return (
          <GameCategoryWrapper
            viewAll={false}
            setref={i}
            key={i}
            category={category}
            data={n.edges}
            gameLink={categoryName}
          />
        )
      })}
    </div>
  )
}

export default AllCategories
